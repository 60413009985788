import React from "react";

import { Text } from "src/components/shared";

import "./regulations.scss";

import Competition from "src/assets/images/competition.png";
import Plan from "src/assets/images/plan.png";
import Statute from "src/assets/images/statute.png";
import StatuteRU from "src/assets/images/statute-ru.png";

export const RegulationsComponent = React.memo(() => {
  return (
    <div className="regulations">
      <div className="regulations__regulation">
        <img className="regulations__img" src={Plan} alt="" />
        <div className="regulations__label">
          <a href={`${process.env.PUBLIC_URL}/plan2025.pdf`}>
            <Text className="regulations__link" path="regulations.plan" />
          </a>
        </div>
      </div>
      <div className="regulations__regulation">
        <img className="regulations__img" src={Competition} alt="" />
        <div className="regulations__label">
          <a href={`${process.env.PUBLIC_URL}/competition2024.pdf`}>
            <Text className="regulations__link" path="regulations.competition" />
          </a>
        </div>
      </div>
      <div className="regulations__regulation">
        <img className="regulations__img" src={Statute} alt="" />
        <div className="regulations__label">
          <a href={`${process.env.PUBLIC_URL}/statute.pdf`}>
            <Text className="regulations__link" path="regulations.statute" />
          </a>
        </div>
      </div>
      <div className="regulations__regulation">
        <img className="regulations__img" src={StatuteRU} alt="" />
        <div className="regulations__label">
          <a href={`${process.env.PUBLIC_URL}/statute_ru.pdf`}>
            <Text className="regulations__link" path="regulations.statuteRU" />
          </a>
        </div>
      </div>
    </div>
  );
});

RegulationsComponent.displayName = "RegulationsComponent";
